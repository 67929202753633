"use strict";
'kiwi public';

var _typeof3 = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/typeof"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/slicedToArray"));
var _assign = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/assign"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _findIndex = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find-index"));
var _fromEntries = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/from-entries"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _entries = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/entries"));
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
var _Logger = _interopRequireDefault(require("@/libs/Logger"));
function _getRequireWildcardCache(e) { if ("function" != typeof _WeakMap) return null; var r = new _WeakMap(), t = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof3(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? _Object$getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var log = _Logger.default.namespace('ServerSelector');
var _default2 = exports.default = {
  props: {
    enableCustom: {
      type: Boolean,
      default: true
    },
    showPath: {
      type: Boolean,
      default: false
    },
    usePreset: {
      type: Boolean,
      default: true
    },
    connection: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      presetNetworks: [],
      presetSelected: 'custom',
      serverAddress: '',
      serverPath: '',
      willEmit: false
    };
  },
  computed: {
    presetServer: {
      set: function set(newVal) {
        this.presetSelected = newVal;
        this.$emit('selected', newVal);
        var conn = newVal === 'custom' ? Misc.getDefaultConnection() : this.presetNetworks[newVal];
        (0, _assign.default)(this.connection, conn);
      },
      get: function get() {
        return this.presetSelected;
      }
    },
    server: {
      get: function get() {
        return this.showPath ? this.connection.server + this.connection.path : this.connection.server;
      },
      set: function set(value) {
        var parts = value.split('/');
        this.connection.server = parts[0];
        if (parts.length > 1) {
          this.connection.path = "/".concat((0, _slice.default)(parts).call(parts, 1).join('/'));
        } else {
          this.connection.path = '';
        }
      }
    },
    serverProtocols: function serverProtocols() {
      return this.$state.getSetting('settings.serverProtocols') || ['irc', 'ircs', 'ws', 'wss'];
    },
    protocol: {
      get: function get() {
        var proto = this.connection.direct ? 'ws' : 'irc';
        if (this.connection.tls) {
          proto += 's';
        }
        return proto;
      },
      set: function set(newProto) {
        var proto = this.protocol;
        var portMap = {
          irc: 6667,
          ircs: 6697,
          ws: 8067,
          wss: 8097
        };
        if (portMap[proto] === this.connection.port && portMap[newProto]) {
          this.connection.port = portMap[newProto];
        }
        switch (newProto) {
          case 'irc':
            this.connection.tls = false;
            this.connection.direct = false;
            break;
          case 'ircs':
            this.connection.tls = true;
            this.connection.direct = false;
            break;
          case 'ws':
            this.connection.tls = false;
            this.connection.direct = true;
            break;
          case 'wss':
            this.connection.tls = true;
            this.connection.direct = true;
            break;
          default:
            log.error('invalid protocol');
        }
      }
    }
  },
  created: function created() {
    var _context;
    var networkList = this.$state.getSetting('settings.presetNetworks') || [];
    if (networkList) {
      this.importUris(networkList);
    }

    // If the given network is in the preset server list, select it
    var con = this.connection;
    var presetIdx = (0, _findIndex.default)(_context = this.presetNetworks).call(_context, function (s) {
      var match = s.server === con.server && s.port === con.port && s.tls === con.tls && s.direct === con.direct && s.path === con.path;
      return match;
    });
    if (presetIdx > -1) {
      this.presetServer = presetIdx.toString();
    } else if (!this.enableCustom && !this.connection.server && this.presetNetworks.length) {
      this.presetServer = '0';
    }
  },
  methods: {
    onServerPaste: function onServerPaste(event) {
      event.preventDefault();
      var pasted = event.clipboardData.getData('text');
      var conn = Misc.parsePresetServer(pasted);
      if (conn) {
        (0, _assign.default)(this.connection, conn);
      } else {
        this.connection.server = pasted;
      }
    },
    toggleTls: function toggleTls() {
      var _context2,
        _this = this;
      var protocolMap = (0, _fromEntries.default)((0, _filter.default)(_context2 = (0, _entries.default)({
        irc: 'ircs',
        ircs: 'irc',
        ws: 'wss',
        wss: 'ws'
      })).call(_context2, function (_ref) {
        var _context3, _context4;
        var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];
        return (0, _includes.default)(_context3 = _this.serverProtocols).call(_context3, key) && (0, _includes.default)(_context4 = _this.serverProtocols).call(_context4, value);
      }));
      if (protocolMap[this.protocol]) {
        this.protocol = protocolMap[this.protocol];
      }
    },
    importUris: function importUris(serverList) {
      // [ 'freenode|irc.freenode.net:+6697', 'irc.snoonet.org:6667' ]
      var servers = [];
      serverList.forEach(function (server) {
        if ((0, _typeof2.default)(server) === 'object') {
          var _conn = Misc.getDefaultConnection();
          (0, _assign.default)(_conn, server);
          servers.push(_conn);
          return;
        }
        var conn = Misc.parsePresetServer(server);
        if (!conn) {
          log.error('failed to parse presetNetwork:', server);
          return;
        }
        servers.push(conn);
      });
      this.$set(this, 'presetNetworks', servers);
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ServerSelector"]
window._kiwi_exports.components.ServerSelector = exports.default ? exports.default : exports;
