"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));
var _assign = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/assign"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _lodash = _interopRequireDefault(require("lodash"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));
var _Logger = _interopRequireDefault(require("@/libs/Logger"));
var _CommonLayout = _interopRequireDefault(require("./CommonLayout"));
var _ServerSelector = _interopRequireDefault(require("../ServerSelector"));
function _getRequireWildcardCache(e) { if ("function" != typeof _WeakMap) return null; var r = new _WeakMap(), t = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? _Object$getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var log = _Logger.default.namespace('CustomServer');
var _default = exports.default = {
  components: {
    StartupLayout: _CommonLayout.default,
    ServerSelector: _ServerSelector.default
  },
  data: function data() {
    return {
      nick: '',
      password: '',
      show_password_box: false,
      network: null,
      isFragment: false,
      showSelector: false,
      termsAccepted: false,
      presetSelected: 'custom',
      connectErrors: [],
      connection: Misc.getDefaultConnection()
    };
  },
  computed: {
    startupOptions: function startupOptions() {
      return this.$state.getSetting('settings.startupOptions');
    },
    greetingText: function greetingText() {
      var greeting = this.startupOptions.greetingText;
      if (typeof greeting === 'string') {
        return greeting;
      }
      if (this.isFragment) {
        return this.$t('start_greeting');
      }
      return this.$t('customserver_where');
    },
    showTabs: function showTabs() {
      var _this$startupOptions$;
      if (this.isFragment || this.presetSelected !== 'custom') {
        return false;
      }
      return (_this$startupOptions$ = this.startupOptions.enableTabs) !== null && _this$startupOptions$ !== void 0 ? _this$startupOptions$ : true;
    },
    termsContent: function termsContent() {
      var terms = this.startupOptions.termsContent;
      return typeof terms === 'string' ? terms : '';
    },
    buttonText: function buttonText() {
      var greeting = this.startupOptions.buttonText;
      return typeof greeting === 'string' ? greeting : this.$t('connect');
    },
    footerText: function footerText() {
      var footer = this.startupOptions.footerText;
      return typeof footer === 'string' ? footer : '';
    },
    isNickValid: function isNickValid() {
      var nickPatternStr = this.$state.getSetting('settings.startupOptions.nick_format');
      var nickPattern = '';
      if (!nickPatternStr) {
        // Nicks cannot start with [0-9- ]
        // ? is not a valid nick character but we allow it as it gets replaced
        // with a number.
        nickPattern = /^[a-z_\\[\]{}^`|][a-z0-9_\-\\[\]{}^`|]*$/i;
      } else {
        // Support custom pattern matches. Eg. only '@example.com' may be allowed
        // on some IRCDs
        var pattern = '';
        var flags = '';
        if (nickPatternStr[0] === '/') {
          // Custom regex
          var pos = nickPatternStr.lastIndexOf('/');
          pattern = nickPatternStr.substring(1, pos);
          flags = nickPatternStr.substr(pos + 1);
        } else {
          // Basic contains rule
          pattern = _lodash.default.escapeRegExp(nickPatternStr);
          flags = 'i';
        }
        try {
          nickPattern = new RegExp(pattern, flags);
        } catch (error) {
          log.error('Nick format error: ' + error.message);
          return false;
        }
      }
      return this.nick.match(nickPattern);
    },
    readyToStart: function readyToStart() {
      var ready = !!this.nick;
      if (!this.connection.server) {
        return false;
      }
      if (!this.isNickValid) {
        ready = false;
      }
      if (this.termsContent && !this.termsAccepted) {
        ready = false;
      }
      return ready;
    }
  },
  created: function created() {
    var _context,
      _this = this;
    var options = this.startupOptions;
    if (Misc.queryStringVal('nick')) {
      this.nick = Misc.queryStringVal('nick');
    } else {
      this.nick = options.nick;
    }
    this.nick = Misc.processNickRandomNumber(this.nick);
    if (options.bouncer) {
      this.connection.type = 'bnc';
    }
    var saveThisSessionsState = false;

    // If we have networks from a previous state, launch directly into it
    if (this.$state.networks.length > 0) {
      var network = this.$state.networks[0];
      this.$state.setActiveBuffer(network.id, network.serverBuffer().name);
      saveThisSessionsState = true;
      this.$emit('start');
    } else if ((0, _slice.default)(_context = window.location.hash).call(_context, 1)) {
      var _context2, _context3;
      var fragment = decodeURIComponent((0, _slice.default)(_context2 = window.location.hash).call(_context2, 1));
      var connections = [];
      (0, _filter.default)(_context3 = fragment.split(';')).call(_context3, function (serverStr) {
        return !!(0, _trim.default)(serverStr).call(serverStr);
      }).forEach(function (serverStr) {
        var conn = Misc.parsePresetServer(serverStr);
        if (conn) {
          connections.push(conn);
        }
      });

      // If more than 1 connection string is given, skip the connection screen
      // and add them all right away.
      if (connections.length === 0) {
        saveThisSessionsState = true;
        this.applyDefaults();
      } else if (connections.length === 1) {
        saveThisSessionsState = false;
        this.isFragment = true;
        (0, _assign.default)(this.connection, connections[0]);
      } else if (connections.length > 1) {
        saveThisSessionsState = false;
        connections.forEach(function (conn, idx) {
          var _conn$params;
          var nick = options.nick;
          if ((_conn$params = conn.params) !== null && _conn$params !== void 0 && _conn$params.has('nick')) {
            nick = conn.params.get('nick');
          } else {
            nick = Misc.queryStringVal('nick') || nick;
          }
          nick = Misc.processNickRandomNumber(nick);
          var password = conn.password || '';
          var net = _this.createNetwork(conn, nick, password);

          // Set the first server buffer active
          if (idx === 0) {
            _this.$state.setActiveBuffer(net.id, net.serverBuffer().name);
          }
        });
        this.$emit('start');
      }
    } else {
      saveThisSessionsState = true;
      this.applyDefaults();
    }
    if (saveThisSessionsState) {
      this.$state.persistence.watchStateForChanges();
    }
  },
  methods: {
    readableStateError: function readableStateError(err) {
      return Misc.networkErrorMessage(err);
    },
    createNetwork: function createNetwork(conn, nick, password, netName) {
      var _context4;
      var options = this.startupOptions;
      var net = this.$state.addNetwork(TextFormatting.t('network'), nick, {
        server: conn.server,
        port: conn.port,
        tls: conn.tls,
        direct: conn.direct,
        path: conn.path,
        password: password,
        encoding: (0, _trim.default)(_context4 = options.encoding || 'utf-8').call(_context4),
        gecos: options.gecos,
        username: options.username
      });
      if (conn.type === 'bnc') {
        var _context5, _context6, _context7;
        // Bouncer mode uses server PASS
        net.connection.password = netName ? (0, _concat.default)(_context5 = (0, _concat.default)(_context6 = "".concat(this.nick, "/")).call(_context6, netName, ":")).call(_context5, password) : (0, _concat.default)(_context7 = "".concat(this.nick, ":")).call(_context7, password);
        net.password = '';
      } else {
        net.connection.password = '';
        net.password = password;
      }
      return net;
    },
    startUp: function startUp() {
      var _this2 = this;
      var conn = this.connection;
      var netList = [];
      if (conn.type === 'irc') {
        this.network = this.createNetwork(conn, this.nick, this.password);

        // Only switch to the first channel we join if multiple are being joined
        var hasSwitchedActiveBuffer = false;
        var bufferObjs = Misc.extractBuffers(conn.channels);
        bufferObjs.forEach(function (bufferObj) {
          var newBuffer = _this2.$state.addBuffer(_this2.network.id, bufferObj.name);
          newBuffer.enabled = true;
          if (newBuffer && !hasSwitchedActiveBuffer) {
            _this2.$state.setActiveBuffer(_this2.network.id, newBuffer.name);
            hasSwitchedActiveBuffer = true;
          }
          if (bufferObj.key) {
            newBuffer.key = bufferObj.key;
          }
        });
        if (!hasSwitchedActiveBuffer) {
          this.$state.setActiveBuffer(this.network.id, this.network.serverBuffer().name);
        }
      } else {
        var _context8, _context9;
        // conn.type === 'bnc'
        netList = (0, _map.default)(_context8 = (0, _filter.default)(_context9 = conn.channels.split(',')).call(_context9, function (n) {
          return !!n;
        })).call(_context8, function (n) {
          return (0, _trim.default)(n).call(n);
        });
        this.network = this.createNetwork(conn, this.nick, this.password, netList.shift());
        this.$state.setActiveBuffer(this.network.id, this.network.serverBuffer().name);
      }
      this.network.ircClient.connect();
      var _onRegistered = function onRegistered() {
        if (_this2.$refs.layout) {
          _this2.$refs.layout.close();
        }
        _this2.network.ircClient.off('registered', _onRegistered);
        _this2.network.ircClient.off('close', _onClosed);
        _this2.network.ircClient.off('irc error', onError);
        netList.forEach(function (netName) {
          var net = _this2.createNetwork(conn, _this2.nick, _this2.password, netList.shift());
          net.ircClient.connect();
        });
      };
      var _onClosed = function onClosed() {
        var _context10;
        var lastError = _this2.network.last_error;
        if (lastError && !(0, _includes.default)(_context10 = _this2.connectErrors).call(_context10, lastError)) {
          _this2.connectErrors.push(lastError);
        }
        _this2.network.ircClient.off('registered', _onRegistered);
        _this2.network.ircClient.off('close', _onClosed);
        _this2.network.ircClient.off('irc error', onError);
      };
      var onError = function onError(event) {
        var _context11;
        if (!event.reason || (0, _includes.default)(_context11 = _this2.connectErrors).call(_context11, event.reason)) {
          return;
        }
        _this2.connectErrors.push(event.reason);
      };
      this.network.ircClient.once('registered', _onRegistered);
      this.network.ircClient.once('close', _onClosed);
      this.network.ircClient.on('irc error', onError);
    },
    applyDefaults: function applyDefaults() {
      var options = this.startupOptions;
      this.showSelector = true;
      var port = options.port || 6697;
      (0, _assign.default)(this.connection, {
        server: options.server || '',
        port: port,
        tls: options.tls || port !== 6667,
        direct: options.direct || false,
        path: options.direct_path || '',
        type: options.bouncer ? 'bnc' : 'irc',
        channels: options.channel || ''
      });
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["startups"]) window._kiwi_exports["components"]["startups"] = {};
window._kiwi_exports["components"]["startups"]["CustomServer"]
window._kiwi_exports.components.startups.CustomServer = exports.default ? exports.default : exports;
