"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-serverselector",
    class: {
      'kiwi-serverselector--custom': _vm.presetServer === 'custom'
    }
  }, [_vm.usePreset && _vm.presetNetworks.length > 0 ? _c('div', {
    staticClass: "kiwi-serverselector-presets"
  }, [_c('label', {
    attrs: {
      "for": "kiwi_preset_server"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.presetServer === 'custom' ? 'preset_servers' : 'server')) + " ")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.presetServer,
      expression: "presetServer"
    }],
    staticClass: "u-input",
    attrs: {
      "id": "kiwi_preset_server"
    },
    on: {
      "change": function change($event) {
        var _context;
        var $$selectedVal = (0, _map.default)(_context = (0, _filter.default)(Array.prototype).call($event.target.options, function (o) {
          return o.selected;
        })).call(_context, function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.presetServer = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_vm.enableCustom ? [_c('option', {
    attrs: {
      "value": "custom"
    }
  }, [_vm._v(_vm._s(_vm.$t('custom_server')))]), _c('option', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v("—")])] : _vm._e(), _vm._l(_vm.presetNetworks, function (s, idx) {
    return _c('option', {
      key: 'preset_' + idx,
      domProps: {
        "value": idx
      }
    }, [_vm._v(_vm._s(s.name))]);
  })], 2)]) : _vm._e(), _c('transition-expand', [_vm.presetSelected === 'custom' || _vm.presetNetworks.length === 0 || !_vm.usePreset ? _c('div', {
    staticClass: "kiwi-serverselector-connection"
  }, [_c('div', {
    staticClass: "kiwi-serverselector-connection-proto"
  }, [_c('label', {
    attrs: {
      "for": "kiwi_server_proto"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('protocol')) + " ")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.protocol,
      expression: "protocol"
    }],
    staticClass: "u-input",
    attrs: {
      "id": "kiwi_server_proto"
    },
    on: {
      "change": function change($event) {
        var _context2;
        var $$selectedVal = (0, _map.default)(_context2 = (0, _filter.default)(Array.prototype).call($event.target.options, function (o) {
          return o.selected;
        })).call(_context2, function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.protocol = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.serverProtocols, function (s, idx) {
    return _c('option', {
      key: 'proto_' + idx,
      domProps: {
        "value": s
      }
    }, [_vm._v(_vm._s(s) + "://")]);
  }), 0)]), _c('input-text', {
    directives: [{
      name: "focus",
      rawName: "v-focus"
    }],
    staticClass: "kiwi-serverselector-connection-address",
    attrs: {
      "label": _vm.$t('server')
    },
    on: {
      "paste": _vm.onServerPaste
    },
    model: {
      value: _vm.server,
      callback: function callback($$v) {
        _vm.server = $$v;
      },
      expression: "server"
    }
  }), _c('input-text', {
    staticClass: "kiwi-serverselector-connection-port",
    attrs: {
      "label": _vm.$t('settings_port'),
      "type": "number"
    },
    model: {
      value: _vm.connection.port,
      callback: function callback($$v) {
        _vm.$set(_vm.connection, "port", $$v);
      },
      expression: "connection.port"
    }
  }, [_c('span', {
    staticClass: "kiwi-serverselector-connection-tls",
    on: {
      "click": _vm.toggleTls
    }
  }, [_c('i', {
    staticClass: "fa fa-stack-1x fa-fw",
    class: [_vm.connection.tls ? 'fa-lock' : 'fa-unlock']
  })])])], 1) : _vm._e()])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];