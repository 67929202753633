"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("@/res/globalStyle.css");
var _tinycon = _interopRequireDefault(require("tinycon"));
var _StateBrowser = _interopRequireDefault(require("@/components/StateBrowser"));
var _AppSettings = _interopRequireDefault(require("@/components/AppSettings"));
var _Container = _interopRequireDefault(require("@/components/Container"));
var _ControlInput = _interopRequireDefault(require("@/components/ControlInput"));
var _MediaViewer = _interopRequireDefault(require("@/components/MediaViewer"));
var _UserAvatarCommon = _interopRequireDefault(require("@/components/UserAvatarCommon"));
var _Sidebar = require("@/components/Sidebar");
var _ThemeManager = _interopRequireDefault(require("@/libs/ThemeManager"));
var Notifications = _interopRequireWildcard(require("@/libs/Notifications"));
var bufferTools = _interopRequireWildcard(require("@/libs/bufferTools"));
var _Logger = _interopRequireDefault(require("@/libs/Logger"));
function _getRequireWildcardCache(e) { if ("function" != typeof _WeakMap) return null; var r = new _WeakMap(), t = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? _Object$getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var log = _Logger.default.namespace('App.vue');
var _default = exports.default = {
  components: {
    StateBrowser: _StateBrowser.default,
    Container: _Container.default,
    ControlInput: _ControlInput.default,
    MediaViewer: _MediaViewer.default,
    AvatarCommon: _UserAvatarCommon.default
  },
  props: ['startupComponent'],
  data: function data() {
    return {
      hasStarted: false,
      // When on mobile screens, the statebrowser turns into a drawer
      stateBrowserDrawOpen: false,
      // If set, will become the main view instead of a buffer/nicklist container
      activeComponent: null,
      activeComponentProps: {},
      // If set, will become the main view when no networks are available to be shown
      // and there is no active component set
      fallbackComponent: null,
      fallbackComponentProps: {},
      mediaviewerOpen: false,
      mediaviewerUrl: '',
      mediaviewerComponent: null,
      mediaviewerComponentProps: {},
      mediaviewerIframe: false,
      sidebarState: new _Sidebar.State()
    };
  },
  computed: {
    networks: function networks() {
      return this.$state.networks;
    },
    network: function network() {
      return this.$state.getActiveNetwork();
    },
    buffer: function buffer() {
      return this.$state.getActiveBuffer();
    },
    currentThemeName: function currentThemeName() {
      var theme = _ThemeManager.default.instance().currentTheme();
      return theme ? theme.name.toLowerCase() : '';
    }
  },
  created: function created() {
    var _this = this;
    this.listenForActiveComponents();
    this.watchForThemes();
    this.initStateBrowser();
    this.initMediaviewer();
    this.configureFavicon();
    this.listen(document, 'visibilitychange', this.onVisibilityChange);
    this.listen(document, 'keydown', function (event) {
      return _this.onKeyDown(event);
    });
    this.listen(window, 'focus', function (event) {
      return _this.onFocus(event);
    });
    this.listen(window, 'blur', function (event) {
      return _this.onBlur(event);
    });
    this.listen(window, 'touchstart', function (event) {
      return _this.onTouchStart(event);
    });
  },
  mounted: function mounted() {
    this.trackWindowDimensions();
  },
  methods: {
    // Triggered by a startup screen event
    startUp: function startUp(opts) {
      var _this2 = this;
      log('startUp()');
      if (opts && opts.fallbackComponent) {
        this.fallbackComponent = opts.fallbackComponent;
      }
      if (opts && opts.fallbackComponentProps) {
        this.fallbackComponentProps = opts.fallbackComponentProps;
      }

      // Make sure a startup screen can't trigger these more than once
      if (!this.hasStarted) {
        this.warnOnPageClose();

        // Wait for a click or sending a message before asking for notification permission.
        // Not doing this on an input event will get it blocked by some browsers.
        var _requestNotificationPermission = function requestNotificationPermission() {
          _this2.$state.$off('document.clicked', _requestNotificationPermission);
          _this2.$state.$off('input.raw', _requestNotificationPermission);
          Notifications.requestPermission(_this2.$state);
          Notifications.listenForNewMessages(_this2.$state);
        };
        this.$state.$once('document.clicked', _requestNotificationPermission);
        this.$state.$once('input.raw', _requestNotificationPermission);
      }
      this.hasStarted = true;
    },
    listenForActiveComponents: function listenForActiveComponents() {
      var _this3 = this;
      this.listen(this.$state, 'active.component', function (component, props) {
        _this3.activeComponent = null;
        if (component) {
          _this3.activeComponentProps = props;
          _this3.activeComponent = component;
        }
      });
      this.listen(this.$state, 'active.component.toggle', function (component, props) {
        if (component === _this3.activeComponent) {
          _this3.activeComponent = null;
        } else if (component) {
          _this3.activeComponentProps = props;
          _this3.activeComponent = component;
        }
      });
    },
    watchForThemes: function watchForThemes() {
      var _this4 = this;
      this.listen(this.$state, 'theme.change', function () {
        _this4.$state.clearNickColours();
      });
    },
    initStateBrowser: function initStateBrowser() {
      var _this5 = this;
      this.listen(this.$state, 'statebrowser.toggle', function () {
        _this5.stateBrowserDrawOpen = !_this5.stateBrowserDrawOpen;
      });
      this.listen(this.$state, 'statebrowser.show', function () {
        _this5.stateBrowserDrawOpen = true;
      });
      this.listen(this.$state, 'statebrowser.hide', function () {
        _this5.stateBrowserDrawOpen = false;
      });
    },
    initMediaviewer: function initMediaviewer() {
      var _this6 = this;
      this.listen(this.$state, 'mediaviewer.show', function (url) {
        var opts = {};

        // The passed url may be a string or an options object
        if (typeof url === 'string') {
          opts = {
            url: url
          };
        } else {
          opts = url;
        }
        _this6.mediaviewerUrl = opts.url;
        _this6.mediaviewerComponent = opts.component;
        _this6.mediaviewerComponentProps = opts.componentProps;
        _this6.mediaviewerIframe = opts.iframe;
        _this6.mediaviewerOpen = true;
      });
      this.listen(this.$state, 'mediaviewer.hide', function () {
        _this6.mediaviewerOpen = false;
      });
    },
    configureFavicon: function configureFavicon() {
      var _this7 = this;
      // favicon bubble
      _tinycon.default.setOptions({
        width: 7,
        height: 9,
        color: '#ffffff',
        background: '#b32d2d',
        fallback: true
      });
      this.$state.$watch('ui.favicon_counter', function (newVal) {
        if (newVal) {
          _tinycon.default.setBubble(newVal);
        } else {
          _tinycon.default.reset();
        }
      });
      this.listen(this.$state, 'message.new', function (event) {
        var message = event.message;
        if (!message.isHighlight || message.ignore || _this7.$state.ui.app_has_focus) {
          return;
        }
        _this7.$state.ui.favicon_counter++;
      });
    },
    trackWindowDimensions: function trackWindowDimensions() {
      var _this8 = this;
      // Track the window dimensions into the reactive ui state
      var trackWindowDims = function trackWindowDims() {
        _this8.$state.ui.app_width = _this8.$el.clientWidth;
        _this8.$state.ui.app_height = _this8.$el.clientHeight;
        _this8.$state.ui.is_narrow = _this8.$el.clientWidth <= 769;
      };
      this.listen(window, 'resize', trackWindowDims);
      trackWindowDims();
    },
    warnOnPageClose: function warnOnPageClose() {
      var _this9 = this;
      window.onbeforeunload = function () {
        if (_this9.$state.setting('warnOnExit')) {
          return _this9.$t('window_unload');
        }
        return undefined;
      };
      window.onunload = function () {
        _this9.$state.networks.forEach(function (net) {
          if (net.connection.direct && net.state === 'connected') {
            net.ircClient.raw('QUIT', _this9.$state.setting('quitMessage') || 'Client Closed Connection');
          }
        });
      };
    },
    emitBufferPaste: function emitBufferPaste(event) {
      // bail if no buffer is active, or the buffer is hidden by another component
      if (!this.$state.getActiveBuffer() || this.activeComponent !== null) {
        return;
      }

      // bail if the target is an input-like element
      if (event.target instanceof HTMLInputElement || event.target instanceof HTMLSelectElement || event.target instanceof HTMLTextAreaElement) {
        return;
      }
      this.$state.$emit('buffer.paste', event);
    },
    emitDocumentClick: function emitDocumentClick(event) {
      this.$state.$emit('document.clicked', event);
    },
    onTouchStart: function onTouchStart(event) {
      // Parts of the UI adjust themselves if we're known to be using a touchscreen
      this.$state.ui.is_touch = true;
    },
    onBlur: function onBlur(event) {
      this.$state.ui.app_has_focus = false;
    },
    onFocus: function onFocus(event) {
      this.$state.ui.app_has_focus = true;
      this.$state.ui.favicon_counter = 0;
    },
    onVisibilityChange: function onVisibilityChange(event) {
      var newState = document.visibilityState === 'visible';
      var buffer = this.$state.getActiveBuffer();
      if (buffer) {
        buffer.isVisible = newState;
      }
      this.$state.ui.app_is_visible = newState;
    },
    onKeyDown: function onKeyDown(event) {
      this.$state.$emit('document.keydown', event);
      var meta = false;
      if (navigator.appVersion.indexOf('Mac') !== -1) {
        meta = event.metaKey;
      } else {
        // none english languages use ctrl + alt to access extended chars
        // make sure we do not interfere with that by only acting on ctrl
        meta = event.ctrlKey && !event.altKey;
      }
      if (meta && event.key === ']') {
        // meta + ]
        var buffer = bufferTools.getNextBuffer();
        if (buffer) {
          this.$state.setActiveBuffer(buffer.networkid, buffer.name);
        }
        event.preventDefault();
      } else if (meta && event.key === '[') {
        // meta + [
        var _buffer = bufferTools.getPreviousBuffer();
        if (_buffer) {
          this.$state.setActiveBuffer(_buffer.networkid, _buffer.name);
        }
        event.preventDefault();
      } else if (meta && event.key === 'o') {
        // meta + o
        this.$state.$emit('active.component.toggle', _AppSettings.default);
        event.preventDefault();
      } else if (meta && event.key === 's') {
        // meta + s
        var network = this.$state.getActiveNetwork();
        if (network) {
          network.showServerBuffer('settings');
        }
        event.preventDefault();
      }
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["App"]
window._kiwi_exports.components.App = exports.default ? exports.default : exports;
