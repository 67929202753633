"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('startup-layout', {
    ref: "layout",
    staticClass: "kiwi-customserver",
    scopedSlots: _vm._u([{
      key: "connection",
      fn: function fn() {
        var _vm$startupOptions$en;
        return [_c('form', {
          staticClass: "u-form kiwi-customserver-form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.startUp.apply(null, arguments);
            }
          }
        }, [_c('h2', {
          domProps: {
            "innerHTML": _vm._s(_vm.greetingText)
          }
        }), _vm.network && (_vm.connectErrors.length > 0 || _vm.network.state_error) ? _c('div', {
          staticClass: "kiwi-customserver-error"
        }, [_vm.connectErrors.length > 0 ? _vm._l(_vm.connectErrors, function (err) {
          return _c('span', {
            key: err
          }, [_vm._v(_vm._s(err))]);
        }) : [_c('span', [_vm._v(_vm._s(_vm.$t('network_noconnect')))]), _c('span', [_vm._v(_vm._s(_vm.readableStateError(_vm.network.state_error)))])]], 2) : _vm._e(), _vm.showSelector ? _c('server-selector', {
          attrs: {
            "enable-custom": (_vm$startupOptions$en = _vm.startupOptions.enableCustom) !== null && _vm$startupOptions$en !== void 0 ? _vm$startupOptions$en : true,
            "show-path": true,
            "connection": _vm.connection
          },
          on: {
            "selected": function selected($event) {
              _vm.presetSelected = $event;
            }
          }
        }) : _vm._e(), _c('tabbed-view', {
          ref: "tabs",
          staticClass: "kiwi-customserver-tabs",
          attrs: {
            "show-tabs": _vm.showTabs
          },
          model: {
            value: _vm.connection.type,
            callback: function callback($$v) {
              _vm.$set(_vm.connection, "type", $$v);
            },
            expression: "connection.type"
          }
        }, [_c('tabbed-tab', {
          attrs: {
            "header": "Network",
            "name": "irc"
          }
        }, [_c('input-text', {
          staticClass: "kiwi-customserver-nick",
          class: {
            'kiwi-customserver-nick--invalid': !_vm.isNickValid
          },
          attrs: {
            "label": _vm.$t('nick')
          },
          model: {
            value: _vm.nick,
            callback: function callback($$v) {
              _vm.nick = $$v;
            },
            expression: "nick"
          }
        }), _c('label', {
          staticClass: "kiwi-customserver-have-password"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.show_password_box,
            expression: "show_password_box"
          }],
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(_vm.show_password_box) ? _vm._i(_vm.show_password_box, null) > -1 : _vm.show_password_box
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.show_password_box,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && (_vm.show_password_box = (0, _concat.default)($$a).call($$a, [$$v]));
                } else {
                  var _context;
                  $$i > -1 && (_vm.show_password_box = (0, _concat.default)(_context = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context, (0, _slice.default)($$a).call($$a, $$i + 1)));
                }
              } else {
                _vm.show_password_box = $$c;
              }
            }
          }
        }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('password_have')) + " ")])]), _c('transition-expand', [_vm.show_password_box ? _c('input-text', {
          directives: [{
            name: "focus",
            rawName: "v-focus"
          }],
          attrs: {
            "label": _vm.$t('password'),
            "show-plain-text": true,
            "type": "password"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }) : _vm._e()], 1), _c('input-text', {
          attrs: {
            "label": _vm.$t('channels')
          },
          model: {
            value: _vm.connection.channels,
            callback: function callback($$v) {
              _vm.$set(_vm.connection, "channels", $$v);
            },
            expression: "connection.channels"
          }
        })], 1), _c('tabbed-tab', {
          attrs: {
            "header": "Bouncer",
            "name": "bnc"
          }
        }, [_c('input-text', {
          staticClass: "kiwi-customserver-nick",
          attrs: {
            "label": _vm.$t('username')
          },
          model: {
            value: _vm.nick,
            callback: function callback($$v) {
              _vm.nick = $$v;
            },
            expression: "nick"
          }
        }), _c('input-text', {
          attrs: {
            "label": _vm.$t('password'),
            "type": "password"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('input-text', {
          attrs: {
            "label": _vm.$t('networks')
          },
          model: {
            value: _vm.connection.channels,
            callback: function callback($$v) {
              _vm.$set(_vm.connection, "channels", $$v);
            },
            expression: "connection.channels"
          }
        })], 1)], 1), _vm.termsContent ? _c('div', {
          staticClass: "kiwi-customserver-terms"
        }, [_c('div', [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.termsAccepted,
            expression: "termsAccepted"
          }],
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(_vm.termsAccepted) ? _vm._i(_vm.termsAccepted, null) > -1 : _vm.termsAccepted
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.termsAccepted,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && (_vm.termsAccepted = (0, _concat.default)($$a).call($$a, [$$v]));
                } else {
                  var _context2;
                  $$i > -1 && (_vm.termsAccepted = (0, _concat.default)(_context2 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context2, (0, _slice.default)($$a).call($$a, $$i + 1)));
                }
              } else {
                _vm.termsAccepted = $$c;
              }
            }
          }
        })]), _c('div', {
          staticClass: "kiwi-customserver-terms-content",
          domProps: {
            "innerHTML": _vm._s(_vm.termsContent)
          }
        })]) : _vm._e(), _c('button', {
          staticClass: "u-button u-button-primary u-submit kiwi-customserver-start",
          attrs: {
            "disabled": !_vm.readyToStart,
            "type": "submit"
          }
        }, [!_vm.network || _vm.network.state === 'disconnected' ? _c('div', {
          domProps: {
            "innerHTML": _vm._s(_vm.buttonText)
          }
        }) : [_c('i', {
          staticClass: "fa fa-spin fa-spinner",
          attrs: {
            "aria-hidden": "true"
          }
        })]], 2), _vm.footerText ? _c('div', {
          domProps: {
            "innerHTML": _vm._s(_vm.footerText)
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = exports.staticRenderFns = [];